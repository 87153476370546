<template>
  <div id="intelligentDiagnosis">
    <div class="pages hidden" id="intelligentDiagnosisPages">
      <div class="page_title"><span class="text hidden" id="intelligentDiagnosisText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <div ref="refMarketBackHolder" v-if="marketBackVisible">
        <p ref="refMarketBackDesc"></p>
        <bar-chart ref="refMarketBackChart" id="marketBackChart" customClass="bar_chart" />
      </div>
      <div ref="refHistoryBackHolder" v-if="historyBackVisible">
        <p ref="refHistoryBackDesc"></p>
        <table-chart ref="refHistoryBackTable" id="historyBackTable" customClass="quote_table_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import barChart from "@/components/barChart/index_v1.0.vue";
import tableChart from "@/components/tableChart/index.vue";

export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      marketBackVisible: false,
      historyBackVisible: false,
    };
  },
  components: {
    barChart,
    tableChart
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        
        $("#intelligentDiagnosisText").addClass("hidden");
        this.marketBackVisible = false
        this.historyBackVisible = false
        this.updateDate = ""
        resolve();
      });
    },
    async show(commandData) {
      this.marketBackVisible = false
      this.historyBackVisible = false
      this.updateDate = ""
      //设置标题
      const title = commandData.data.pageTitle;
      $("#intelligentDiagnosisPages").removeClass("hidden");
      $("#intelligentDiagnosisText").html(title);

      this.$nextTick(async () => {
        $("#intelligentDiagnosisText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#intelligentDiagnosisText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (['月份历史行情回看', '星期历史行情回看', '每年同期历史行情回看'].indexOf(commandData.type) !== -1) {
          this.handleMarketBack(commandData)
        } else {
          this.handleHistoryBack(commandData)
        }
      });
    },
    handleMarketBack(commandData) {
      this.marketBackVisible = true;
      this.$nextTick(() => {
        this.renderMarketBack(commandData)
      });
    },
    renderMarketBack(commandData) {
      let width = this.$refs.refMarketBackHolder.clientWidth;
      this.$refs.refMarketBackDesc.innerHTML = commandData.data.data.desc
      this.$refs.refMarketBackChart.BarChart(commandData.data.data.data, {
        x: (d) => d.name,
        y: (d) => d.value,
        width: width,
        height: width * 0.76,
        marginLeft: 60,
        marginBottom: 40,
        marginTop: 30,
        duration: 1000,
        delay: 70,
        ease: "easeCircle",
        isShowNumber: false,
        xPadding: 0.3,
        // numberSuffix: "%",
        ySuffix: "%",
        yTickNumber: 8,
        xTickNumber: 10,
      });
      this.updateDate = commandData.data.data.date
    },
    handleHistoryBack(commandData) {
      this.historyBackVisible = true;
      this.$nextTick(() => {
        this.renderHistoryBack(commandData)
      });
    },
    renderHistoryBack(commandData) {
      let data = [['股票名称', '上涨次数', '平均涨幅']]
      commandData.data.data.data.forEach(item => {
        data.push([
          item.sSecName,
          item.iUpYear,
          item.fRisePct
        ])
      });
      let width = this.$refs.refHistoryBackHolder.clientWidth;
      this.$refs.refHistoryBackDesc.innerHTML = commandData.data.data.desc
      this.$refs.refHistoryBackTable.TableChart(data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        col3Suffix: "%",
        col2Suffix: "次",
      });
      this.updateDate = commandData.data.data.date
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
